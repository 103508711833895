.portfolio {
  background-color: white;
  min-width: 100%;
  min-height: 100%; //important, this makes it only take up the full viewport (not the entire webpage)
  z-index: 1;
  padding-bottom: 3em;
}

.headingText {
  padding: 1em;
  text-align: center;
  font-family: 'Open Sans' sans-serif;
  font-weight: 300;
  font-display: swap;
}

.photographyTab {
  width: 100%;
  max-height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0) !important;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  margin: auto;
  cursor: pointer;
  opacity: 1 !important;
}
.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==) no-repeat center !important;
}
.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+) no-repeat center !important;
}



.projectsTab {
  width: 100%;
  max-height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}


.project-container {
  padding-top: 10px;
  padding-bottom: 50px;
}

.tool-container {
  text-decoration: none;
  pointer-events: none;
  background-color: #f5f5f5;
  border-radius: 25px;
  margin: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  color: black;
  width: auto;
  height: auto;

  &:hover {
    text-decoration: none;
    color: black;
  }
  #tool-icon2 {
    width: 25px;
    height: auto;
    margin-right: 0.5em;
  }
}

.modal-title {
  margin: 0 auto;
  padding-top: 0.25em;
  font-family: "Roboto-Slab", sans-serif;
  font-weight: 600;
}

.btn-close {
  position: absolute;
  right: 1rem;
}

.btn-primary#download {
  transition: 500ms;
  background-image: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(104, 9, 121, 1) 25%,
    rgba(0, 212, 255, 1) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  &:hover {
    transform: scale(0.9);

    background-image: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(104, 9, 121, 1) 25%,
      rgba(0, 212, 255, 1) 100%
    );
    background-size: 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}

#project-description {
  margin-top: 10px;
}

.projectLink {
  text-decoration: none;
  background-color: #f5f5f5;
  border-radius: 25px;
  border-width: 0.15em;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  background-image: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(104, 9, 121, 1) 25%,
    rgba(0, 212, 255, 1) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

Button#poster-button {
  transition: 500ms;

  &:hover {
    background-color: #f5f5f5;
    background-image: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(104, 9, 121, 1) 25%,
      rgba(0, 212, 255, 1) 100%
    );
    background-size: 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    transform: scale(0.9);
  }
}
.githubLink {
  text-decoration: none;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  color: black;
}
.devpostLink {
  text-decoration: none;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  margin-left: 20px;
  color: black;
}
.figmaLink {
  text-decoration: none;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  margin-left: 20px;
  color: black;
}



@media screen and (max-width: 420px) {
  .project-container {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 397px) {
 
  

  .project-links-container {
    display: flex;
  }
  .githubLink {
    text-decoration: none;
    background-color: #f5f5f5;
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    color: black;
    font-size: 0.9rem;
  }
  .devpostLink {
    text-decoration: none;
    background-color: #f5f5f5;
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    margin-left: 5px;
    color: black;
    font-size: 0.9rem;
  }
  .figmaLink {
    text-decoration: none;
    background-color: #f5f5f5;
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    margin-left: 5px;
    color: black;
    font-size: 0.9rem;
  }
}





@media screen and (max-width: 300px) {
  .project-links-container {
    display: flex;
  }
  .githubLink {
    text-decoration: none;
    background-color: #f5f5f5;
    border-radius: 25px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    padding-right: 5px;
    color: black;
    font-size: 0.6rem;
  }
  .devpostLink {
    text-decoration: none;
    background-color: #f5f5f5;
    border-radius: 25px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    padding-right: 5px;
    margin-left: 5px;
    color: black;
    font-size: 0.6rem;
  }
  .figmaLink {
    text-decoration: none;
    background-color: #f5f5f5;
    border-radius: 25px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    padding-right: 5px;
    margin-left: 5px;
    color: black;
    font-size: 0.6rem;
  }
}
