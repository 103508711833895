.home {
  max-width: 100%;
  min-height: 100vh;
  background-color: #f8efef;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .hiddenHome {
    display: flex;
    min-height: 100%;
    min-width: 50%;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgba(18, 22, 28, 0.95);
    border-radius: 25px;
    z-index: -1;
    margin-bottom: 5%;
    opacity: 0;
    transition: all 1.5s;
  }

  .particlejs {
    max-width: auto;
    max-height: auto;
    color: blue;
  }
  .home__container {
    display: flex;
    min-height: 100%;
    min-width: 50%;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgba(18, 22, 28, 0.95);
    opacity: 1;
    border-radius: 25px;
    z-index: -1;
    margin-bottom: 5%;
  }

  .name-header{
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 3rem;
  }

  .email-header{
    text-align: center;
    margin-left: 0;
    justify-content: center;
  }

  .button-area{
    display: flex;
    text-align: center;
    margin-left: 0;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .row#name{
    margin-bottom: 0;
  }
  .hiddenDescription {
    opacity: 0;
    transition: all 1.5s;
    transition-delay: 2.5s;
  }
  .Typewriter {
    color: white;
    text-transform: uppercase;
    font-size: 1.375rem;
    opacity: 1;
  }

  .text {
    line-height: 2.188rem;

    p {
      font-family: "Open Sans", sans-serif;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      font-size: 1.375rem;
    }
    .email-text {
      color: rgb(255, 255, 255);
      font-size: 0.75rem;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: lighter;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    h1 {
      font-weight: 500;
      font-size: 4.5rem;
      color: rgb(255, 255, 255);
    }
  }

  .portrait-icon {
    max-width: 325px;
    max-height: 325px;
    pointer-events: none;
    width: auto;
    height: auto;
    border-radius: 50%;
  }

  .lazy-load-image-background {
    display: inline !important;
  }

  .infoCol {
    padding: 2.75em;
    justify-content: center;
    align-items: center;
  }
  .portraitCol {
    padding: 2.75em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // ------------------------------Scroll arrow icon-----------------------------------------------

  .hiddenArrow {
    opacity: 0;
    transition: all 1s;
    transition-delay: 3s;
  }
  .arrowDown {
    position: absolute;
    top: 85%;
    // align-items: center;
    justify-content: space-between;
    cursor: pointer;
    opacity: 1;

    -moz-animation: bounce 4s infinite;
    -webkit-animation: bounce 4s infinite;
    animation: bounce 4s infinite;

    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }
  }
}

$color_1: transparent;
$color_2: #eee;
$background-color_1: #25d8d7;
$background-color_2: #222;
$background-color_3: transparent;

/* ============================Resume and Portfolio Buttons=====================================================*/
.noselect {
  user-select: none;
  color: $color_1;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  margin: 0 3px;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: $background-color_1;
  background-image: linear-gradient(316deg, #50d8d7 0%, #923993 74%);
  transition: 500ms;
  &:hover {
    &::after {
      background-color: $background-color_3;
    }
    transform: scale(0.9);
  }
  &:focus {
    outline: none;
  }
}
#resume-button {
  &::after {
    z-index: 1000;
    width: 143px;
    height: 43px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "View Résumé";
    font-size: 1rem;
    color: $color_2;
    border-radius: 25px;
    background-color: $background-color_2;
    transition: 500ms;
  }
}

#portfolio-button {
  &::after {
    width: 143px;
    height: 43px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "My Portfolio";
    font-size: 1rem;
    color: $color_2;
    border-radius: 25px;
    background-color: $background-color_2;
    transition: 500ms;
  }
}

.modal-backdrop {
  z-index: 1040 !important;
}

.modal-title {
  margin: 0 auto;
  padding-top: 0.25em;
  font-family: "Roboto-Slab", sans-serif;
  font-weight: 600;
}

.btn-close {
  position: absolute;
  right: 1rem;
}

.btn-primary#download {
  transition: 500ms;
  background-image: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(104, 9, 121, 1) 25%,
    rgba(0, 212, 255, 1) 100%
  );
  background-size: 100%;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  &:hover {
    transform: scale(0.9);

    background-image: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(104, 9, 121, 1) 25%,
      rgba(0, 212, 255, 1) 100%
    );
    background-size: 100%;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}

.modal-content {
  margin: 2px auto;
  border: 3px solid #1976d2;
  max-width: 100%;
  min-height: 20%;

  z-index: 1100 !important;
}

/* =========================Social Media Icons================================================= */

$color_1: #666;
$color_2: #456cba;
$color_3: #26a4f2;
$color_4: #e23670;

ul#social-icons {
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0%;
  li {
    position: relative;
    display: block;
    color: $color_1;
    font-size: 1.875rem;
    height: 60px;
    width: 60px;
    background: #171515;
    line-height: 55px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: 0.5s;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: inherit;
      width: inherit;
      border-radius: 50%;
      transform: scale(0.9);
      z-index: -1;
      transition: 0.5s;
    }
    &:hover {
      &:before {
        filter: blur(3px);
        transform: scale(1.2);
      }
    }
  }
  li#linkedin {
    &:before {
      background: #4267b2;
    }
    &:hover {
      &:before {
        box-shadow: 0 0 15px #4267b2;
      }
      color: $color_2;
      box-shadow: 0 0 15px #4267b2;
      text-shadow: 0 0 15px #4267b2;
    }
  }
  li#github {
    &:before {
      background: #1da1f2;
    }
    &:hover {
      &:before {
        box-shadow: 0 0 15px #1da1f2;
      }
      color: $color_3;
      box-shadow: 0 0 15px #1da1f2;
      text-shadow: 0 0 15px #1da1f2;
    }
  }
  li#instagram {
    &:before {
      background: #e1306c;
    }
    &:hover {
      &:before {
        box-shadow: 0 0 15px #e1306c;
      }
      color: $color_4;
      box-shadow: 0 0 15px #e1306c;
      text-shadow: 0 0 15px #e1306c;
    }
  }
}

// Media Query for when decreasing screen size to smaller desktop screen level
@media only screen and (max-width: 992px) {
  .home {
    min-width: 100%;
    min-height: 100vh;
    background-color: #f8efef;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .hiddenHome {
      display: flex;
      max-height: 100%;
      min-width: 80%;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(18, 22, 28, 0.95);
      border-radius: 25px;
      z-index: -1;
      margin-bottom: 20%;
      opacity: 0;
      transition: all 1.5s;
    }

    .home__container {
      display: flex;
      max-height: 100%;
      min-width: 80%;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(18, 22, 28, 0.95);
      opacity: 1;
      border-radius: 25px;
      z-index: -1;
      margin-bottom: 20%;
    }

    .hiddenDescription {
      opacity: 0;
      transition: all 1.5s;
      transition-delay: 2.5s;
    }
    .Typewriter {
      color: white;
      text-transform: uppercase;
      font-size: 1.375rem;
      opacity: 1;
    }

    .text {
      line-height: 2.188rem;

      p {
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-size: 1.375rem;
      }
      .email-text {
        color: rgb(255, 255, 255);
        font-size: 0.75rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-weight: lighter;
      }

      h1 {
        font-size: 4rem;
        color: rgb(255, 255, 255);
      }
    }

    .portrait-icon {
      max-width: 312px;
      max-height: 312px;
      pointer-events: none;
      width: auto;
      height: auto;
      border-radius: 50%;
    }

    .infoCol {
      padding: 1.5em;
      justify-content: center;
      align-items: center;
    }
    .portraitCol {
      padding: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// Media Query for when decreasing screen size to tablet level
@media only screen and (max-width: 767px) {
  .home {
    min-width: 100%;
    min-height: 100vh;
    background-color: #f8efef;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .hiddenHome {
      display: flex;
      max-height: 100%;
      min-width: 80%;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(18, 22, 28, 0.95);
      border-radius: 25px;
      z-index: -1;
      margin-bottom: 30%;
      opacity: 0;
      transition: all 1.5s;
    }

    .home__container {
      display: flex;
      max-height: 100%;
      min-width: 80%;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(18, 22, 28, 0.95);
      opacity: 1;
      border-radius: 25px;
      z-index: -1;
      margin-bottom: 30%;
    }

    .hiddenDescription {
      opacity: 0;
      transition: all 1.5s;
      transition-delay: 2.5s;
    }
    .Typewriter {
      color: white;
      text-transform: uppercase;
      font-size: 1.1rem;
      opacity: 1;
    }

    .text {
      line-height: 2.188rem;

      p {
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-size: 1.1rem;
      }
      .email-text {
        color: rgb(255, 255, 255);
        font-size: 0.55rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-weight: lighter;
      }

      h1 {
        font-size: 3rem;
        color: rgb(255, 255, 255);
      }
    }

    .portrait-icon {
      max-width: 258px;
      max-height: 258px;
      pointer-events: none;
      width: auto;
      height: auto;
      border-radius: 50%;
    }

    // .lazy-load-image-background {
    //   display: inline !important;
    // }

    .infoCol {
      max-width: 50%;
      padding: 1em;
      justify-content: center;
      align-items: center;
    }
    .portraitCol {
      max-width: 50%;
      padding: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  $color_1: transparent;
  $color_2: #eee;
  $background-color_1: #25d8d7;
  $background-color_2: #222;
  $background-color_3: transparent;

  /* ============================Resume and Portfolio Buttons=====================================================*/
  .noselect {
    user-select: none;
    color: $color_1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 40px;
    margin: 0 4px;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    background-color: $background-color_1;
    background-image: linear-gradient(316deg, #50d8d7 0%, #923993 74%);
    transition: 500ms;
    &:hover {
      &::after {
        font-size: 0.9rem;
        background-color: $background-color_3;
      }
      transform: scale(0.9);
    }
    &:focus {
      outline: none;
    }
  }
  #resume-button {
    &::after {
      z-index: 1000;
      width: 105px;
      height: 33px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: "View Résumé";
      font-size: 0.8rem;
      color: $color_2;
      border-radius: 25px;
      background-color: $background-color_2;
      transition: 500ms;
    }
  }

  #portfolio-button {
    &::after {
      z-index: 1000;
      width: 105px;
      height: 33px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: "My Portfolio";
      font-size: 0.8rem;
      color: $color_2;
      border-radius: 25px;
      background-color: $background-color_2;
      transition: 500ms;
    }
  }

  /* =========================Social Media Icons================================================= */

  $color_1: #666;
  $color_2: #456cba;
  $color_3: #26a4f2;
  $color_4: #e23670;

  ul#social-icons {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 0%;
    li {
      position: relative;
      display: block;
      color: $color_1;
      font-size: 25px;
      height: 45px;
      width: 45px;
      background: #171515;
      line-height: 35px;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      transition: 0.5s;
      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: inherit;
        width: inherit;
        border-radius: 50%;
        transform: scale(0.3);
        z-index: -1;
        transition: 0.5s;
      }
      &:hover {
        &:before {
          filter: blur(3px);
          transform: scale(1.2);
        }
      }
    }
  }

  .linkedinIcon {
    transform: scale(0.75);
  }
  .githubIcon {
    transform: scale(0.75);
  }
  .instagramIcon {
    transform: scale(0.75);
  }
}

// Media query for when our width is around smartphone level so we have the portrait icon move to below the text
@media only screen and (max-width: 600px) {
  .home {
    min-width: 1%;
    min-height: 100vh;
    background-color: #f8efef;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .hiddenHome {
      display: flex;
      min-height: 50%;
      max-width: 5%;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(18, 22, 28, 0.95);
      border-radius: 25px;
      z-index: -1;
      margin-bottom: 30%;
      opacity: 0;
      transition: all 1.5s;
    }

    .home__container {
      display: flex;
      min-height: 50%;
      max-width: 5%;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(18, 22, 28, 0.95);
      opacity: 1;
      border-radius: 25px;
      z-index: -1;
      margin-bottom: 30%;
    }

    .hiddenDescription {
      opacity: 0;
      transition: all 1.5s;
      transition-delay: 2.5s;
    }
    .Typewriter {
      color: white;
      text-transform: uppercase;
      font-size: 1.1rem;
      opacity: 1;
    }

    .text {
      line-height: 1.5rem;

      p {
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-size: 1.1rem;
      }
      .email-text {
        color: rgb(255, 255, 255);
        font-size: 0.55rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-weight: lighter;
      }

      h1 {
        font-size: 3rem;
        color: rgb(255, 255, 255);
      }
    }

    .portrait-icon {
      width: auto;
      max-height: 232px;
      pointer-events: none;
      width: auto;
      height: auto;
      border-radius: 50%;
    }

    .infoCol {
      max-width: 100%;
      padding: 0.5em;
      // justify-content: center;
      // align-items: center;
    }
    .portraitCol {
      max-width: 100%;
      // padding: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  $color_1: transparent;
  $color_2: #eee;
  $background-color_1: #25d8d7;
  $background-color_2: #222;
  $background-color_3: transparent;

  /* ============================Resume and Portfolio Buttons=====================================================*/
  .noselect {
    user-select: none;
    color: $color_1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    height: 35px;
    margin: 0 4px;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    background-color: $background-color_1;
    background-image: linear-gradient(316deg, #50d8d7 0%, #923993 74%);
    transition: 500ms;
    &:hover {
      &::after {
        font-size: 0.9rem;
        background-color: $background-color_3;
      }
      transform: scale(0.9);
    }
    &:focus {
      outline: none;
    }
  }
  #resume-button {
    &::after {
      z-index: 1000;
      width: 98px;
      height: 28px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: "View Résumé";
      font-size: 0.7rem;
      color: $color_2;
      border-radius: 25px;
      background-color: $background-color_2;
      transition: 500ms;
    }
  }

  #portfolio-button {
    &::after {
      z-index: 1000;
      width: 98px;
      height: 28px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: "My Portfolio";
      font-size: 0.7rem;
      color: $color_2;
      border-radius: 25px;
      background-color: $background-color_2;
      transition: 500ms;
    }
  }

  /* =========================Social Media Icons================================================= */

  $color_1: #666;
  $color_2: #456cba;
  $color_3: #26a4f2;
  $color_4: #e23670;

  ul#social-icons {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 0%;
    li {
      position: relative;
      display: block;
      color: $color_1;
      font-size: 20px;
      height: 45px;
      width: 45px;
      background: #171515;
      line-height: 40px;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      transition: 0.5s;
      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: inherit;
        width: inherit;
        border-radius: 50%;
        transform: scale(0.5);
        z-index: -1;
        transition: 0.5s;
      }
      &:hover {
        &:before {
          filter: blur(3px);
          transform: scale(1.2);
        }
      }
    }
  }

  .linkedinIcon {
    transform: scale(0.75);
  }
  .githubIcon {
    transform: scale(0.75);
  }
  .instagramIcon {
    transform: scale(0.75);
  }
}

@media only screen and (max-width: 420px) {
  .home {
    min-width: 1%;
    min-height: 100vh;
    background-color: #f8efef;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .hiddenHome {
      display: flex;
      min-height: 50%;
      max-width: 5%;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(18, 22, 28, 0.95);
      border-radius: 25px;
      z-index: -1;
      opacity: 0;
      transition: all 1.5s;
    }

    .home__container {
      display: flex;
      min-height: 50%;
      max-width: 5%;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: rgba(18, 22, 28, 0.95);
      opacity: 1;
      border-radius: 25px;
      z-index: -1;
      // margin-bottom: 40%;
    }

    .hiddenDescription {
      opacity: 0;
      transition: all 1.5s;
      transition-delay: 2.5s;
    }
    .Typewriter {
      color: white;
      text-transform: uppercase;
      font-size: 0.95rem;
      opacity: 1;
    }

    .text {
      line-height: 1rem;

      p {
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-size: 1.1rem;
      }
      .email-text {
        color: rgb(255, 255, 255);
        font-size: 0.5rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-weight: lighter;
      }

      h1 {
        font-size: 2.5rem;
        color: rgb(255, 255, 255);
      }
    }

    .portrait-icon {
      width: auto;
      max-height: 195px;
      pointer-events: none;
      width: auto;
      height: auto;
      border-radius: 50%;
    }

    .infoCol {
      max-width: 100%;
      padding: 0em;
      // justify-content: center;
      // align-items: center;
    }
    .portraitCol {
      max-width: 100%;
      // padding: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  $color_1: transparent;
  $color_2: #eee;
  $background-color_1: #25d8d7;
  $background-color_2: #222;
  $background-color_3: transparent;

  /* ============================Resume and Portfolio Buttons=====================================================*/
  .noselect {
    user-select: none;
    color: $color_1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 30px;
    margin: 0 4px;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    background-color: $background-color_1;
    background-image: linear-gradient(316deg, #50d8d7 0%, #923993 74%);
    transition: 500ms;
    &:hover {
      &::after {
        font-size: 0.9rem;
        background-color: $background-color_3;
      }
      transform: scale(0.9);
    }
    &:focus {
      outline: none;
    }
  }
  #resume-button {
    &::after {
      z-index: 1000;
      width: 88px;
      height: 23px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: "View Résumé";
      font-size: 0.7rem;
      color: $color_2;
      border-radius: 25px;
      background-color: $background-color_2;
      transition: 500ms;
    }
  }

  #portfolio-button {
    &::after {
      z-index: 1000;
      width: 88px;
      height: 23px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: "My Portfolio";
      font-size: 0.7rem;
      color: $color_2;
      border-radius: 25px;
      background-color: $background-color_2;
      transition: 500ms;
    }
  }

  /* =========================Social Media Icons================================================= */

  $color_1: #666;
  $color_2: #456cba;
  $color_3: #26a4f2;
  $color_4: #e23670;

  ul#social-icons {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 0%;
    li {
      position: relative;
      display: block;
      color: $color_1;
      font-size: 20px;
      height: 45px;
      width: 45px;
      background: #171515;
      line-height: 40px;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      transition: 0.5s;
      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: inherit;
        width: inherit;
        border-radius: 50%;
        transform: scale(0.5);
        z-index: -1;
        transition: 0.5s;
      }
      &:hover {
        &:before {
          filter: blur(3px);
          transform: scale(1.2);
        }
      }
    }
  }

  .linkedinIcon {
    transform: scale(0.75);
  }
  .githubIcon {
    transform: scale(0.75);
  }
  .instagramIcon {
    transform: scale(0.75);
  }
}
