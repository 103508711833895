.footer-container1 {
  // min-width: 100%;
  // display: grid;
  text-align: start;
}
.footer-container2 {
  // display: grid;
  text-align: end;
  // min-width: 100%;
}
.footer {
  min-width: 100%;
  z-index: 1;
  padding-left: 10em;
  padding-right: 10em;
}

p#trademark {
  font-family: "Courier New", Courier, monospace;
  min-width: 100%;
}
p#location {
  font-family: "Courier New", Courier, monospace;
}
.textra {
  font-weight: 500;
}

/* =========================Footer Social Media Icons================================================= */

$color_1: #666;
$color_2: #456cba;
$color_3: #26a4f2;
$color_4: #e23670;

ul#footer-icons {
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0%;
  li {
    position: relative;
    display: block;
    color: $color_1;
    font-size: 30px;
    height: 40px;
    width: 40px;
    background: #f5f5f5;
    line-height: 35px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: 0.5s;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: inherit;
      width: inherit;
      border-radius: 50%;
      transform: scale(0.9);
      z-index: -1;
      transition: 0.5s;
    }
    &:hover {
      &:before {
        filter: blur(3px);
        transform: scale(1.2);
      }
    }
  }
  li#linkedin {
    &:before {
      background: #4267b2;
    }
    &:hover {
      &:before {
        box-shadow: 0 0 15px #4267b2;
      }
      color: $color_2;
      box-shadow: 0 0 15px #4267b2;
      text-shadow: 0 0 15px #4267b2;
    }
  }
  li#github {
    &:before {
      background: #1da1f2;
    }
    &:hover {
      &:before {
        box-shadow: 0 0 15px #1da1f2;
      }
      color: $color_3;
      box-shadow: 0 0 15px #1da1f2;
      text-shadow: 0 0 15px #1da1f2;
    }
  }
  li#instagram {
    &:before {
      background: #e1306c;
    }
    &:hover {
      &:before {
        box-shadow: 0 0 15px #e1306c;
      }
      color: $color_4;
      box-shadow: 0 0 15px #e1306c;
      text-shadow: 0 0 15px #e1306c;
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    min-width: 100%;
    z-index: 1;
    padding-left: 3em;
    padding-right: 3em;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    min-width: 100%;
    z-index: 1;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  p#trademark {
    font-family: "Courier New", Courier, monospace;
    font-size: 0.75rem;
  }
  p#location {
    font-family: "Courier New", Courier, monospace;
    font-size: 0.75rem;
  }
}
